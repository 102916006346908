<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="可创建Wi-Fi码身份">
              <el-checkbox-group v-model="form.creator_role"><br>
                <el-checkbox label="normal">
                  普通用户
                  新openid扫码奖励金额
                  <el-input-number size="mini" v-model="form.normal_award"></el-input-number>
                  元
                </el-checkbox>
                <el-checkbox label="card-member">
                  免单会员
                  新openid扫码奖励金额
                  <el-input-number size="mini" v-model="form.card_member_award"></el-input-number>
                  元
                </el-checkbox>
                <el-checkbox label="card-merchant">
                  免单商家
                  新openid扫码奖励金额
                  <el-input-number size="mini" v-model="form.card_merchant_award"></el-input-number>
                  元
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="商家Wi-Fi直推人奖励">
              <el-input-number size="mini" v-model="form.merchant_invite_award"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="链接Wi-Fi强制手机号">
              <el-switch v-model="form.connect_must_phone"></el-switch>
            </el-form-item>
            <el-form-item label="openid连接记录默认展示头像">
              <y-upload-img v-model="form.default_avatar"></y-upload-img>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>

import YUploadImg from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "edit",
  components: {YUploadImg},
  data() {
    return {
      creatorRole: [],
      form: {
        creator_role: [],
        normal_award: 0,
        card_member_award: 0,
        card_merchant_award: 0,
        merchant_invite_award: 0,
        connect_must_phone: false,
        default_avatar: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.wifi.conf().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.card.plugin.wifi.confEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>