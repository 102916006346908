<template>
  <div>
    <div @click="show=true"><slot></slot></div>
    <el-dialog custom-class="confirm-input-custom-dialog" width="30rem" append-to-body :show-close="false" :visible.sync="show" destroy-on-close>
      <div class="flex-def">
        <el-input size="default" v-model="value"></el-input>
        <el-button style="margin-left: 1rem" size="default" @click="submit" type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "confirmInput",
  model:{
    prop:"modelVal",
    event:"change"
  },
  props:{
    modelVal:{
      type:String,
      default:"",
    },
    sync:{
      type:Boolean,
      default: true,
    },
  },
  watch:{
    modelVal:{
      handler:"modelValHandler",
      immediate:true
    },
    value(){
      if (this.sync)this.$emit('change',this.value);
    },
  },
  methods:{
    submit(){
      this.$emit('confirm',this.value);
      this.show = false;
    },
    modelValHandler(){
      this.value = this.modelVal
    },
  },
  data(){
    return{
      value:"",
      show:false,
    }
  },
}
</script>

<style>
.confirm-input-custom-dialog > .el-dialog__body{
  padding: 1rem;
}
.confirm-input-custom-dialog > .el-dialog__header{
  padding: 0;
}
</style>